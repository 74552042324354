import React, { useCallback, useEffect, useRef, useState } from "react";
import "./style.scss";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import de from "assets/images/de.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import danisikLogo from "assets/images/danisik_logo.png";
import bernerLogo from "assets/images/berner_logo.svg";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";
import { useSelector } from "react-redux";
import Euro from "assets/icons/Euro";
import DownloadFile from "assets/icons/DownloadFile";
import { useParams } from "react-router-dom";
import AdvertiseService from "services/product/advertiseService";
import LoadingPage from "components/LoadingPage";
import { statusInfos } from "constants/statusInfos";
import { currencyFormat } from "utils/numberHelperFunctions";
import Dollar from "assets/icons/Dollar";
import Pound from "assets/icons/Pound";
import TurkishLira from "assets/icons/TurkishLira";
import PdfService from "services/product/device/pdfService";
import WhatsappLogoFilled from "assets/icons/WhatsappLogoFilled";
import languageTexts from "constants/languageTexts";
import SwiperComponent from "components/Swiper";
import { saveAs } from "file-saver";
import ReactQuill from "react-quill";
import Keyboard from "assets/icons/Keyboard";
import AccessoryForAdvertise from "components/Modals/AccessoryForAdvertise";

const currencies = {
  EUR: <Euro />,
  USD: <Dollar />,
  GBP: <Pound />,
  TRY: <TurkishLira />,
};

const deviceInfosPdf = {
  serialNumber: true,
  brand: true,
  model: true,
  productionYear: true,
  category: true,
  deviceInfo: true,
  images: true,
  physicalInfos: true,
  packagingInfos: true,
  deviceLocations: true,
};

function convertToNumericPhoneNumber(phoneNumber) {
  // Use a regular expression to match only numeric characters
  const numericPhoneNumber = phoneNumber.replace(/\D/g, "");

  return numericPhoneNumber;
}

const advertiseService = new AdvertiseService();
const pdfService = new PdfService();
function ProductDetail() {
  const textAreaRef = useRef(null);
  const danisikLanguage = useSelector((state) => state.danisikLanguage);
  const [accessoryModalOpen, setAccessoryModalOpen] = useState(false);
  const { id, productType } = useParams();
  const [productDataInfo, setProductDataInfo] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [swiperModalOpen, setSwiperModalOpen] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);

  const [loading, setLoading] = useState(false);

  const [isBuyDropdownOpen, setIsBuyDropdownOpen] = useState(false);

  useEffect(() => {
    let el = textAreaRef.current;
    if (el) {
      el.style.cssText = "height:" + el.scrollHeight + "px";
    }
  }, [productDataInfo]);

  const getDeviceAdvertise = useCallback(() => {
    setLoading(true);
    advertiseService
      .deviceAdvertise(id)
      .then((res) => {
        setProductDataInfo(res.data.objectToReturn);
      })
      .finally(() => setLoading(false));
  }, [id]);
  const getPartAdvertise = useCallback(() => {
    setLoading(true);
    advertiseService
      .partAdvertise(id)
      .then((res) => {
        setProductDataInfo(res.data.objectToReturn);
      })
      .finally(() => setLoading(false));
  }, [id]);
  useEffect(() => {
    if (productType === "device") {
      getDeviceAdvertise();
    } else if (productType === "part") {
      getPartAdvertise();
    }
  }, [productType, getDeviceAdvertise, getPartAdvertise]);

  const handleDownloadPdf = () => {
    if (productType === "device") {
      let pdfInfos = {
        id: id,
        deviceInfo: deviceInfosPdf,
        accessory: [],
        technicalDetails: [],
      };
      setLoading(true);
      pdfService
        .downloadPdf(pdfInfos)
        .then((blob) => {
          const file = new File([blob.data], `${id}-device-info.pdf`, {
            type: "application/pdf",
          });
          saveAs(file);
        })
        .catch((err) => {})
        .finally(() => setLoading(false));
    }
  };

  const images = productDataInfo?.productInfo.images;

  const handleClickImg = (index) => {
    setSwiperModalOpen(true);
    setInitialSlide(index);
  };

  return loading ? (
    <LoadingPage />
  ) : productDataInfo ? (
    <div className="product-detail-page">
      <div className="header">
        <img src={danisikLogo} className="danisik-logo" />
        <div className="header-right-container">
          <h3 className="header-title">Medical Stock Management Tool</h3>
          <img src={bernerLogo} className="berner-logo" />
        </div>
      </div>
      <div className="product-detail-content">
        <div className="images-container">
          {images?.length ? (
            <>
              <Swiper
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "#fff",
                }}
                loop={true}
                spaceBetween={10}
                navigation={true}
                thumbs={{
                  swiper:
                    thumbsSwiper && !thumbsSwiper.destroyed
                      ? thumbsSwiper
                      : null,
                }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="swiper-main"
              >
                {images.map((image, index) => (
                  <SwiperSlide
                    key={image._id}
                    onClick={() => handleClickImg(index)}
                  >
                    <img src={image.url} />
                  </SwiperSlide>
                ))}
              </Swiper>
              <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="swiper-bottom"
              >
                {images.map((image) => (
                  <SwiperSlide key={image._id}>
                    <img src={image.url} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </>
          ) : (
            "No Images"
          )}
        </div>
        <div className="device-info-container">
          <div className="device-info-content">
            <h3>{productDataInfo.productInfo.title}</h3>
            <div className="device-info">
              <span>
                {languageTexts[danisikLanguage].condition}:{" "}
                {statusInfos[productDataInfo.productInfo.condition] ||
                  productDataInfo.productInfo.condition}
              </span>
              <span>{languageTexts[danisikLanguage].quantity}: 1</span>
              <span>
                {languageTexts[danisikLanguage].inStock}:{" "}
                {languageTexts[danisikLanguage].yes}
              </span>
              <span>
                {languageTexts[danisikLanguage].date}:{" "}
                {productDataInfo.productInfo.date}
              </span>
            </div>
          </div>
          <div
            className="buy-btn-container"
            onClick={() => setIsBuyDropdownOpen(!isBuyDropdownOpen)}
          >
            <button className="buy-btn">
              {languageTexts[danisikLanguage].buyThisItem}
            </button>
            <div
              className={`buy-btn-dropdown-items ${
                isBuyDropdownOpen ? "open" : ""
              }`}
            >
              <a href={`mailto: Sales@danisik.de`} className="dropdown-item">
                {languageTexts[danisikLanguage].email}
              </a>
              {productDataInfo.eCommerceInfo?.dotmedLink ? (
                <a
                  target="_blank"
                  href={productDataInfo.eCommerceInfo.dotmedLink}
                  className="dropdown-item"
                >
                  Dotmed
                </a>
              ) : null}
              {productDataInfo.eCommerceInfo?.shopifyLink ? (
                <a
                  target="_blank"
                  href={productDataInfo.eCommerceInfo.shopifyLink}
                  className="dropdown-item"
                >
                  Shopify
                </a>
              ) : null}
              {productDataInfo.eCommerceInfo?.ebayLink ? (
                <a
                  target="_blank"
                  href={productDataInfo.eCommerceInfo.ebayLink}
                  className="dropdown-item"
                >
                  Ebay
                </a>
              ) : null}
            </div>
          </div>
        </div>
        <div className="seller-info-container">
          <div className="seller-info">
            <h3 className="title">
              {languageTexts[danisikLanguage].sellerInfo}
            </h3>
            <h4 className="company-name">
              {productDataInfo.sellerInformation.companyName}
            </h4>
            <h5 className="seller-name">
              {productDataInfo.sellerInformation.sellerName}
            </h5>
            <span className="phone-info">
              <WhatsappLogoFilled />
              <a
                href={`https://api.whatsapp.com/send?phone=${convertToNumericPhoneNumber(
                  productDataInfo.sellerInformation.phone
                )}`}
                target="_blank"
              >
                {productDataInfo.sellerInformation.phone}
              </a>
            </span>
            <div className="location-container">
              <span className="location-title">
                {languageTexts[danisikLanguage].location}
              </span>
              <div className="location">
                <img src={de} />
                <span className="country-name">Germany</span>
              </div>
            </div>
          </div>
          {productType === "device" ? (
            <div className="price">
              <span className="title">
                {languageTexts[danisikLanguage].price}
              </span>
              <div className="price-container">
                {currencyFormat(productDataInfo?.productInfo?.price)}
                {currencies["EUR"]}
              </div>
            </div>
          ) : null}

          {productType === "device" ? (
            <button
              className="download-pdf"
              onClick={() => setAccessoryModalOpen(true)}
            >
              <Keyboard fill="rgba(222, 222, 222, 1)" />
              {languageTexts[danisikLanguage].accessuaryInformations}
            </button>
          ) : null}
        </div>
        <div className="description-container">
          <span className="title">
            {languageTexts[danisikLanguage].description}
          </span>
          <ReactQuill
            value={productDataInfo?.productInfo?.descriptionShopify}
            readOnly
          />
        </div>
      </div>
      <SwiperComponent
        files={images}
        setSwiperModalOpen={setSwiperModalOpen}
        swiperModalOpen={swiperModalOpen}
        initialSlide={initialSlide}
      />
      <AccessoryForAdvertise
        accessories={productDataInfo?.accessories || []}
        isModalOpen={accessoryModalOpen}
        handleCloseModal={() => setAccessoryModalOpen(false)}
      />
    </div>
  ) : (
    <div>{languageTexts[danisikLanguage].noProductDataInfo} </div>
  );
}
export default ProductDetail;
